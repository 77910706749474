@import 'partials/core';

.authenticationMethods {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: $pageBoundary30;

    .logo {
        height: 24px;
        margin-bottom: 16px;
        
        @media only screen and (min-width: $tablet) {
            height: 32px;
        }
    }

    .referralTitle {
        margin-bottom: 16px;
        
        @include RadialBold;
        font-size: 24px;
        letter-spacing: -0.48px;
        text-align: center;
        color: #000;
        
        @media only screen and (min-width: $tablet) {
            font-size: 28px;
        }
    }
    
    .description {
        align-self: center;
        width: 400px;
        max-width: calc(100vw - $pageBoundary30 * 2);
        margin-bottom: 40px;
        
        @include NeueMontreal;
        font-size: 17px;
        line-height: 20px;
        text-align: center;

        @media only screen and (min-width: $tablet) {
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.03em;
        }

        &.tightSpacing {
            margin-bottom: 24px;

            @media only screen and (min-width: $tablet) {
                margin-bottom: 40px;
            }
        }
    }
    
    .buttonContainer {
        width: 500px;
        max-width: calc(100vw - #{ $pageBoundary30 * 2 });

        @include Radial();
        font-size: 18px;
        line-height: 22px;
        text-align: center;

        button {
            margin-bottom: 16px;
        }
    }
}