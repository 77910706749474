@import 'partials/core';

footer.agreementNotice {
    position: absolute;
    right: $pageBoundary;
    bottom: 40px;
    left: $pageBoundary;
    
    @include NeueMontreal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-align: center;
    color: $grey4Hallow;
    
    @media only screen and (min-width: $tablet) {
        bottom: 80px;
    }

    a {
        text-decoration: underline;
    }
}